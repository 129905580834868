import { array, boolean, object, string } from "yup"

export const commonValidationSchema = object({
  submitAsComplete: boolean(),
  campaign: object().when("submitAsComplete", {
    is: true,
    then: object().shape({
      clientName: string()
        .required("必須です")
        .typeError("必須です"),
      twitterAccountName: string()
        .required("必須です")
        .typeError("必須です"),
      grossBudget: string()
        .required("必須です")
        .typeError("必須です"),
      campaignObjective: string()
        .required("必須です")
        .typeError("必須です"),
      campaignName: string()
        .required("必須です")
        .typeError("必須です"),
      campaignDailyBudget: string()
        .required("必須です")
        .typeError("必須です"),
      campaignTotalBudget: string()
        .required("必須です")
        .typeError("必須です"),
      campaignStartDate: string()
        .required("必須です")
        .typeError("必須です"),
      campaignEndDate: string()
        .required("必須です")
        .typeError("必須です"),
      delivery: string()
        .required("必須です")
        .typeError("必須です"),
    }),
  }),
  adGroups: array().when("submitAsComplete", {
    is: true,
    then: array().of(
      object().shape({
        adGroupName: string()
          .required("必須です")
          .typeError("必須です"),
        others: object().shape(
          {
            bidType: string()
              .required("必須です")
              .typeError("必須です"),
            bidAmount: string().when("bidType", (value: string) => {
              if (value === "AUTO_BID") {
                return string()
              } else {
                // 自動入札以外は入札額を入力しなければならない
                return string()
                  .required("必須です")
                  .typeError("必須です")
                  .min(1, "1以上の整数を入力してください")
              }
            }),
            tweetIds: string()
              .required("必須です")
              .typeError("必須です")
              .when("reservationPostTweetIds", (value: string) => {
                if (value && value.length > 0) {
                  return string()
                } else {
                  return string()
                    .required("必須です")
                    .typeError("必須です")
                }
              }),
            reservationPostTweetIds: string()
              .required("必須です")
              .typeError("必須です")
              .when("tweetIds", (value: string) => {
                if (value && value.length > 0) {
                  return string()
                } else {
                  return string()
                    .required("必須です")
                    .typeError("必須です")
                }
              }),
            language: string()
              .required("必須です")
              .typeError("必須です"),
            location: string()
              .required("必須です")
              .typeError("必須です"),
          },
          [["reservationPostTweetIds", "tweetIds"]]
        ),
      })
    ),
  }),
})
