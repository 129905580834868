/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import Button from "@material-ui/core/Button"
import Paper from "@material-ui/core/Paper"
import { useFormikContext } from "formik"
import React from "react"
import { useDispatch } from "react-redux"
import { CommonFormValues } from "src/components/helpers/FormUtils/types/CommonFormValues"
import { commaStringsToNumber } from "src/components/helpers/FormUtils/utils"
import { CampaignInfo } from "src/components/organisms/CampaignInfo"
import { ClientInfo } from "src/components/organisms/ClientInfo"
import { convertCampaignReqBySales } from "src/components/pages/EditOrder/form/utils"
import { campaignOperations } from "src/ducks/campaign"

type OwnProps = {
  children?: never
}

export const ClientCampaignInfo: React.FC<OwnProps> = () => {
  const dispatch = useDispatch()
  const { setFieldValue, values } = useFormikContext<CommonFormValues>()

  const saveAsDraftCampaignAndClient = (): void => {
    const campaign = values.campaign
    if (values.campaign.isEdit) {
      const grossBudget = commaStringsToNumber(campaign.grossBudget)
      const totalBudget = commaStringsToNumber(campaign.campaignTotalBudget)
      if (grossBudget && totalBudget && grossBudget < totalBudget) {
        alert(
          "キャンペーン予算（ネット）がキャンペーン予算（グロス）を超えています"
        )
        return
      }
    }

    if (!values.campaign.isEdit) {
      setFieldValue("campaign.isEdit", true)
      return
    }

    setFieldValue("campaign.isEdit", false)

    dispatch(
      campaignOperations.updateCampaign({
        inhouse_campaign_id: values.campaign.inhouseCampaignId,
        ...convertCampaignReqBySales(values),
      })
    )
  }

  return (
    <Paper elevation={0}>
      <div css={editButton}>
        <Button
          size="small"
          variant={values.campaign.isEdit ? "contained" : "outlined"}
          color="primary"
          disabled={values.campaign.inhouseCampaignStatus >= 3}
          onClick={saveAsDraftCampaignAndClient}
        >
          {values.campaign.isEdit ? "保存する" : "編集する"}
        </Button>
      </div>
      <ClientInfo disabled={!values.campaign.isEdit} />
      <CampaignInfo disabled={!values.campaign.isEdit} />
    </Paper>
  )
}

const editButton = css`
  display: flex;
  justify-content: flex-end;
  padding: 8px;
`
