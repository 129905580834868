/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import Button from "@material-ui/core/Button"
import Paper from "@material-ui/core/Paper"
import { useFormikContext } from "formik"
import React, { Fragment } from "react"
import { CampaignObjective } from "src/components/molecules/CampaignObjective"
import { MultipleFieldRow } from "src/components/molecules/MultipleFieldRow"
import { AdsGroupsInfo } from "src/components/organisms/AdsGroupInfo"
import { CampaignInfo } from "src/components/organisms/CampaignInfo"
import { ClientInfo } from "src/components/organisms/ClientInfo"
import { CommonFormValues } from "src/components/pages/CreateOrder/form/types/CommonFormValues"

type OwnProps = {
  children?: never
}

/**
 * 入稿依頼新規作成コンポーネント
 * 入力フォーム部分
 */
export const CreateForm: React.FC<OwnProps> = () => {
  const { values } = useFormikContext<CommonFormValues>()

  return (
    <div>
      {values.campaign.campaignObjective === "" ? (
        <MultipleFieldRow label="キャンペーンの目的">
          <CampaignObjective />
        </MultipleFieldRow>
      ) : (
        <Fragment>
          <h3>クライアント/キャンペーン情報</h3>
          <Paper elevation={0}>
            <ClientInfo />
            <CampaignInfo />
          </Paper>
          <h3>広告グループ</h3>
          <AdsGroupsInfo />
          <div css={buttonGroup}>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              type="submit"
            >
              下書きに保存
            </Button>
          </div>
        </Fragment>
      )}
    </div>
  )
}

const buttonGroup = css`
  display: flex;
  justify-content: flex-end;
  padding-top: 30px;
`
