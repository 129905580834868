/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import { TableCell, TableRow } from "@material-ui/core"
import { Fragment } from "react"
import { numberWithCommas } from "src/components/helpers/FormUtils/utils"
import {
  AdGroupInner,
  BidPricingUnit,
  OptimizationPreference,
} from "src/domainLayer/models/AdsGroupInfo"
import { CampaignObjective } from "src/domainLayer/models/CampaignInfo"

type OwnProps = {
  adInfo: AdGroupInner
  campaignObjective?: CampaignObjective
  children?: never
}

export const BidDetail: React.FC<OwnProps> = ({
  adInfo,
  campaignObjective,
}) => {
  const { bidType, bidAmount, bidPricingUnit, chargeBy } = adInfo.others

  return (
    <Fragment>
      <TableRow>
        <TableCell component="th" scope="row" css={tableTh}>
          入札タイプ
        </TableCell>
        <TableCell align="left">
          {bidType === "AUTO_BID" && "自動入札"}
          {bidType === "AUTO_BID_WITH_TARGET" && "目標コスト"}
          {bidType === "MAX_BID" && "上限入札"}
        </TableCell>
      </TableRow>
      {bidType !== "AUTO_BID" && (
        <TableRow>
          <TableCell component="th" scope="row" css={tableTh}>
            入札額
          </TableCell>
          <TableCell align="left">{numberWithCommas(bidAmount)}</TableCell>
        </TableRow>
      )}
      {(campaignObjective === "FOLLOWERS" ||
        campaignObjective === "TWEET_ENGAGEMENTS" ||
        campaignObjective === "APP_ENGAGEMENTS" ||
        campaignObjective === "WEBSITE_CLICKS" ||
        campaignObjective === "AWARENESS") &&
      bidType === "AUTO_BID" ? null : (
        <TableRow>
          <TableCell component="th" scope="row" css={tableTh}>
            入札価格単位
          </TableCell>
          <TableCell align="left">
            {displayBidPricingUnit(campaignObjective, bidPricingUnit)}
          </TableCell>
        </TableRow>
      )}
      {campaignObjective === "FOLLOWERS" ||
      campaignObjective === "TWEET_ENGAGEMENTS" ||
      campaignObjective === "APP_ENGAGEMENTS" ||
      campaignObjective === "PROMOTED_VIDEO_VIEWS" ||
      campaignObjective === "IN_STREAM_VIDEO_AD_VIEWS_PREROLL" ? null : (
        <TableRow>
          <TableCell component="th" scope="row" css={tableTh}>
            最適化設定
          </TableCell>
          <TableCell align="left">
            {displayOptimizationPreference(
              campaignObjective,
              adInfo.optimizationPreference
            )}
          </TableCell>
        </TableRow>
      )}
      {campaignObjective === "APP_INSTALLS" && (
        <TableRow>
          <TableCell component="th" scope="row" css={tableTh}>
            請求方法
          </TableCell>
          <TableCell align="left">
            {chargeBy === "INSTALL" ? "インストール" : "アプリのクリック"}
          </TableCell>
        </TableRow>
      )}
    </Fragment>
  )
}

const tableTh = css`
  width: 240px;
`

/**
 * キャンペーンの目的別の入札価格単位を返す
 */
const displayBidPricingUnit = (
  campaignObjective: CampaignObjective | undefined,
  bidPricingUnit: BidPricingUnit
): string => {
  switch (campaignObjective) {
    case "FOLLOWERS":
      return "新規フォロワーあたり"
    case "TWEET_ENGAGEMENTS":
      return "エンゲージメント毎"
    case "APP_ENGAGEMENTS":
      return "アプリクリックあたり"
    case "WEBSITE_CLICKS":
    case "AWARENESS":
      return "1,000インプレッションあたり"
    case "APP_INSTALLS":
      if (bidPricingUnit === "-") {
        return "アプリクリックあたり"
      } else {
        return "インストールあたり"
      }
    case "PROMOTED_VIDEO_VIEWS":
      if (bidPricingUnit === "-") {
        return "3秒/100%表示再生あたり"
      } else if (bidPricingUnit === "COST_PER_VIDEO_VIEW") {
        return "動画再生あたり"
      } else {
        return "6秒の動画再生あたり"
      }
    case "IN_STREAM_VIDEO_AD_VIEWS_PREROLL":
      if (bidPricingUnit === "-") {
        return "3秒/100%のインストリーム動画広告再生あたり"
      } else if (bidPricingUnit === "COST_PER_VIDEO_VIEW") {
        return "インストリーム動画再生あたり"
      } else {
        return "6秒のインストリーム動画再生あたり"
      }
    default:
      return ""
  }
}
/**
 * キャンペーンの目的別の最適化設定を返す
 */
const displayOptimizationPreference = (
  campaignObjective: CampaignObjective | undefined,
  optimizationPreference: OptimizationPreference
): string => {
  switch (campaignObjective) {
    case "WEBSITE_CLICKS":
      if (optimizationPreference === "NONE") {
        return "リンクのクリック数"
      } else {
        return "ウェブサイトコンバージョン"
      }
    case "AWARENESS":
      if (optimizationPreference === "NONE") {
        return "最大リーチ"
      } else {
        return "エンゲージメントを伴うリーチ"
      }
    case "APP_INSTALLS":
      if (optimizationPreference === "NONE") {
        return "アプリのクリック数"
      } else {
        return "インストール"
      }
    default:
      return ""
  }
}
