/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import { TableCell } from "@material-ui/core"
import Paper from "@material-ui/core/Paper"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableRow from "@material-ui/core/TableRow"
import Typography from "@material-ui/core/Typography"
import React, { Fragment } from "react"
import { numberWithCommas } from "src/components/helpers/FormUtils/utils"
import { CampaignDetailInner } from "src/domainLayer/models/CampaignInfo"

type OwnProps = {
  campaignInfo: CampaignDetailInner
  children?: never
}

export const ClientCampaignConfirmArea: React.FC<OwnProps> = ({
  campaignInfo,
}) => {
  return (
    <Fragment>
      <Paper elevation={0}>
        <Typography css={title}>クライアント情報</Typography>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row" css={tableTh}>
                広告主
              </TableCell>
              <TableCell align="left">{campaignInfo.clientName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row" css={tableTh}>
                アカウント名
              </TableCell>
              <TableCell align="left">
                {campaignInfo.twitterAccountName}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row" css={tableTh}>
                コンサル実施
              </TableCell>
              {campaignInfo.consultation ? (
                <TableCell align="left">あり</TableCell>
              ) : (
                <TableCell align="left">なし</TableCell>
              )}
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row" css={tableTh}>
                キャンペーン予算(グロス)
              </TableCell>
              <TableCell align="left">
                {numberWithCommas(campaignInfo.grossBudget)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
      <Paper elevation={0}>
        <Typography css={title}>キャンペーン情報</Typography>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row" css={tableTh}>
                キャンペーンの目的
              </TableCell>
              <TableCell align="left">
                {campaignInfo.campaignObjective}
              </TableCell>
            </TableRow>
            {(campaignInfo.campaignObjective === "APP_INSTALLS" ||
              campaignInfo.campaignObjective === "APP_ENGAGEMENTS") && (
              <TableRow>
                <TableCell component="th" scope="row" css={tableTh}>
                  アプリ
                </TableCell>
                <TableCell align="left">
                  {campaignInfo.campaignAppId != null &&
                    campaignInfo.campaignAppId.length !== 0 && (
                      <div>{campaignInfo.campaignAppId.join(",")}</div>
                    )}
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell component="th" scope="row" css={tableTh}>
                キャンペーン名
              </TableCell>
              <TableCell align="left">{campaignInfo.campaignName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row" css={tableTh}>
                お支払い方法
              </TableCell>
              <TableCell align="left">{campaignInfo.fundingSourceId}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row" css={tableTh}>
                日別予算
              </TableCell>
              <TableCell align="left">
                {numberWithCommas(campaignInfo.campaignDailyBudget)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row" css={tableTh}>
                キャンペーン予算(ネット)
              </TableCell>
              <TableCell align="left">
                {numberWithCommas(campaignInfo.campaignTotalBudget)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row" css={tableTh}>
                出稿開始日時 / 出稿終了日時
              </TableCell>
              <TableCell align="left">
                {campaignInfo.campaignStartDate} ~{" "}
                {campaignInfo.campaignEndDate}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row" css={tableTh}>
                配信ペース
              </TableCell>
              {campaignInfo.delivery ? (
                <TableCell align="left">標準配信</TableCell>
              ) : (
                <TableCell align="left">集中配信</TableCell>
              )}
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    </Fragment>
  )
}

const tableTh = css`
  width: 240px;
`
const title = css`
  margin: 40px 10px 40px 10px;
`
