/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import { Button, Select, TableCell, TableRow } from "@material-ui/core"
import MenuItem from "@material-ui/core/MenuItem"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import { useFormikContext } from "formik"
import React, { useState } from "react"
import { useLocation } from "react-router-dom"
import { CommonFormValues } from "src/components/pages/CreateOrder/form/types/CommonFormValues"
import { CampaignObjective as CampaignObjectiveValue } from "src/domainLayer/models/CampaignInfo"

type OwnProps = {
  children?: never
}
/**
 * キャンペーン情報入力エリア
 */
export const CampaignObjective: React.FC<OwnProps> = () => {
  const { values, setFieldValue } = useFormikContext<CommonFormValues>()

  const [obj, setObj] = useState<CampaignObjectiveValue | "">(
    values.campaign.campaignObjective
  )
  const location = useLocation()
  const isCreate = location.pathname === "/orders/create"
  const currentCampaign = values.campaign.campaignObjective

  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell align="left" css={selectTableTh}>
            <Select
              value={obj}
              onChange={(e) => {
                const obj = e.target.value as CampaignObjectiveValue
                setObj(obj)
                if (isCreate && currentCampaign.length === 0) {
                  return
                }
                // eslint-disable-next-line array-callback-return
                values.adGroups.map((_data, index): void => {
                  setFieldValue(`adGroups.${index}.others.bidPricingUnit`, "-")
                  setFieldValue(`adGroups.${index}.others.chargeBy`, "-")
                  setFieldValue(`adGroups.${index}.others.bidType`, "AUTO_BID")
                  setFieldValue(`adGroups.${index}.others.bidAmount`, 0)
                  setFieldValue(
                    `adGroups.${index}.others.OptimizationPreference`,
                    "NONE"
                  )
                  setFieldValue(
                    `adGroups.${index}.adGroupPlacementValues.PROFILES`,
                    false
                  )
                  setFieldValue(
                    `adGroups.${index}.adGroupPlacementValues.SEARCH_RESULTS`,
                    false
                  )
                  setFieldValue(
                    `adGroups.${index}.adGroupPlacementValues.TIMELINES`,
                    true
                  )
                })
                setFieldValue("campaign.campaignAppId", "")
                setFieldValue("campaign.campaignObjective", obj)
              }}
              variant="outlined"
              displayEmpty
              css={selectTh}
              disabled={isCreate ? false : !values.campaign.isEdit}
            >
              <MenuItem css={selectItem} value="" disabled>
                選択してください
              </MenuItem>
              <MenuItem value="TWEET_ENGAGEMENTS">
                ツイートのエンゲージメント
              </MenuItem>
              <MenuItem value="PROMOTED_VIDEO_VIEWS">
                プロモビデオ再生数
              </MenuItem>
              <MenuItem value="AWARENESS">ブランド認知度の向上</MenuItem>
              <MenuItem value="WEBSITE_CLICKS">
                ウェブサイトへの誘導数またはコンバージョン
              </MenuItem>
              <MenuItem value="IN_STREAM_VIDEO_AD_VIEWS_PREROLL">
                インストリーム動画再生数(プレロール)
              </MenuItem>
              <MenuItem value="FOLLOWERS">フォロワー</MenuItem>
              <MenuItem value="APP_INSTALLS">アプリのインストール数</MenuItem>
              <MenuItem value="APP_ENGAGEMENTS">アプリの起動回数</MenuItem>
            </Select>
            {isCreate && currentCampaign.length === 0 && (
              <Button
                color="primary"
                variant="contained"
                css={button}
                onClick={async () => {
                  // eslint-disable-next-line array-callback-return
                  values.adGroups.map((_data, index): void => {
                    setFieldValue(
                      `adGroups.${index}.others.bidPricingUnit`,
                      "-"
                    )
                    setFieldValue(`adGroups.${index}.others.chargeBy`, "-")
                    setFieldValue(`adGroups.${index}.others.bidAmount`, 0)
                    setFieldValue(
                      `adGroups.${index}.others.OptimizationPreference`,
                      "NONE"
                    )
                    setFieldValue(
                      `adGroups.${index}.adGroupPlacementValues.PROFILES`,
                      false
                    )
                    setFieldValue(
                      `adGroups.${index}.adGroupPlacementValues.SEARCH_RESULTS`,
                      false
                    )
                    setFieldValue(
                      `adGroups.${index}.adGroupPlacementValues.TIMELINES`,
                      true
                    )
                  })
                  setFieldValue("campaign.campaignAppId", "")
                  await setFieldValue("campaign.campaignObjective", obj)
                }}
                disabled={obj === ""}
              >
                選択した目的で新規作成
              </Button>
            )}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

const selectTableTh = css`
  border: none;
  padding: 0px;
`

const selectTh = css`
  width: 240px;
`
const selectItem = css`
  padding: 0px !important;
`
const button = css`
  margin-left: 60px;
`
