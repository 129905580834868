/* eslint-disable @typescript-eslint/no-explicit-any */
/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import React from "react"
import { FastNumberField } from "src/components/atoms/FastNumberField"

type OwnProps = {
  children?: never
  disabled?: boolean
  errorMsg?: string
  fieldName: string
  initialValue: any
  label: string
  onSetValue: (value: any) => void
}

/**
 * ラベルと入力エリアを表示する、テーブル1行分のコンポーネント
 */
export const NumberFieldRow: React.FC<OwnProps> = ({
  label,
  initialValue,
  onSetValue,
  fieldName,
  disabled,
  errorMsg,
}) => {
  return (
    <TableRow>
      <TableCell component="th" scope="row" css={tableTh}>
        {label}
      </TableCell>
      <TableCell align="left">
        <FastNumberField
          initialValue={initialValue}
          onSetValue={onSetValue}
          name={fieldName}
          fullWidth
          variant="outlined"
          size="small"
          disabled={disabled}
          error={!!errorMsg}
          helperText={errorMsg}
        />
      </TableCell>
    </TableRow>
  )
}
const tableTh = css`
  width: 240px;
`
