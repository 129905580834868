import { InputProps, TextField, TextFieldProps } from "@material-ui/core"
import React, { useEffect, useState } from "react"

type OwnProps = {
  initialValue: string | undefined
  onSetValue: (value: string | undefined) => void
} & TextFieldProps

export const FastTextField: React.FC<OwnProps> = ({
  initialValue,
  onSetValue,
  children,
  ...rest
}) => {
  // We need to keep and update the state of the cell normally
  const [tempValue, setTempValue] = useState(initialValue)
  const semiColonList = [
    "commonTailoredAudienceList",
    "commonTailoredAudiencesFromMobileApps",
    "commonTailoredAudiencesWebsiteVisitors",
    "commonFlexibleAudiences",
    "tailoredAudienceListExcluded",
    "tailoredAudiencesFromMobileAppsExcluded",
    "tailoredAudiencesWebsiteVisitorsExcluded",
    "flexibleAudiencesExcluded",
    "language",
    "location",
    "userOsVersion",
    "platform",
    "userDevice",
    "mobileCarriers",
    "broadMatchKeyword",
    "phraseKeywordsExcluded",
    "eventTargetingIds",
    "userInterests",
    "followerTargetingLookalikes",
    "tvShows",
    "conversations",
    "tweetIds",
    "reservationPostTweetIds",
    "mediaCreativeIds",
  ]

  const onChange: InputProps["onChange"] = (e) => {
    setTempValue(e.target.value)
  }

  // We'll only update the external data when the input is blurred
  const _onBlur: InputProps["onBlur"] = () => {
    let newValue = tempValue
    const toBeReplaced = semiColonList.filter((item) => {
      if (rest && rest.name) {
        return rest.name.includes(item)
      } else {
        return false
      }
    })
    if (toBeReplaced.length > 0 && newValue) {
      newValue = newValue.replace(/,/g, ";")
    }
    onSetValue(newValue)
  }

  // If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    setTempValue(initialValue)
  }, [initialValue])

  return (
    <TextField
      {...rest}
      onChange={onChange}
      onBlur={_onBlur}
      value={tempValue}
      multiline
    >
      {children}
    </TextField>
  )
}
