import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import { getIn, useField, useFormikContext } from "formik"
import React from "react"
import { RadioField } from "src/components/atoms/RadioField"
import { CampaignObjective } from "src/components/molecules/CampaignObjective"
import { DateTimePickerRow } from "src/components/molecules/DateTimePickerRow"
import { MultipleFieldRow } from "src/components/molecules/MultipleFieldRow"
import { NumberFieldRow } from "src/components/molecules/NumberFieldRow"
import { RadioFieldRow } from "src/components/molecules/RadioFieldRow"
import { TextFieldRow } from "src/components/molecules/TextFieldRow"
import { CommonFormValues } from "src/components/pages/CreateOrder/form/types/CommonFormValues"

type OwnProps = {
  children?: never
  disabled?: boolean
}
/**
 * キャンペーン情報入力エリア
 */
export const CampaignInfo: React.FC<OwnProps> = ({ disabled }) => {
  const { values, setFieldValue, errors } = useFormikContext<CommonFormValues>()
  const [campaignStartDateField] = useField<Date | null>(
    "campaign.campaignStartDate"
  )
  const [campaignEndDateField] = useField<Date | null>(
    "campaign.campaignEndDate"
  )

  return (
    <Table>
      <TableBody>
        <MultipleFieldRow label="キャンペーンの目的">
          {values.campaign.inhouseCampaignStatus ? (
            values.campaign.campaignObjective
          ) : (
            <CampaignObjective />
          )}
        </MultipleFieldRow>
        {(values.campaign.campaignObjective === "APP_INSTALLS" ||
          values.campaign.campaignObjective === "APP_ENGAGEMENTS") && (
          <TextFieldRow
            initialValue={values.campaign.campaignAppId}
            onSetValue={(value) =>
              setFieldValue(`campaign.campaignAppId`, value)
            }
            fieldName="campaign.campaignAppId"
            label="アプリ"
            placeholder="セミコロン( ; )区切りで入力してください"
            disabled={disabled}
          />
        )}
        <TextFieldRow
          label="キャンペーン名"
          initialValue={values.campaign.campaignName}
          onSetValue={(value) => setFieldValue("campaign.campaignName", value)}
          fieldName="campaign.campaignName"
          disabled={disabled}
          errorMsg={getIn(errors, "campaign.campaignName")}
        />
        <TextFieldRow
          label="お支払い方法"
          initialValue={values.campaign.fundingSourceId}
          onSetValue={(value) =>
            setFieldValue("campaign.fundingSourceId", value)
          }
          fieldName="campaign.fundingSourceId"
          disabled={disabled}
        />
        <NumberFieldRow
          label="日別予算"
          initialValue={values.campaign.campaignDailyBudget}
          onSetValue={(value) =>
            setFieldValue("campaign.campaignDailyBudget", value)
          }
          fieldName="campaign.campaignDailyBudget"
          disabled={disabled}
          errorMsg={getIn(errors, "campaign.campaignDailyBudget")}
        />
        <NumberFieldRow
          label="キャンペーン予算(ネット)"
          initialValue={values.campaign.campaignTotalBudget}
          onSetValue={(value) =>
            setFieldValue("campaign.campaignTotalBudget", value)
          }
          fieldName="campaign.campaignTotalBudget"
          disabled={disabled}
          errorMsg={getIn(errors, "campaign.campaignTotalBudget")}
        />
        <DateTimePickerRow
          label="出稿開始日時"
          field={campaignStartDateField}
          onSetValue={(value) => {
            setFieldValue("campaign.campaignStartDate", value)
          }}
          disabled={disabled}
          errorMsg={getIn(errors, "campaign.campaignStartDate")}
        />
        <DateTimePickerRow
          label="出稿終了日時"
          field={campaignEndDateField}
          onSetValue={(value) => {
            setFieldValue("campaign.campaignEndDate", value)
          }}
          disabled={disabled}
          errorMsg={getIn(errors, "campaign.campaignEndDate")}
        />
        <RadioFieldRow
          label="配信ペース"
          value={values.campaign.delivery}
          fieldName="campaign.delivery"
          errorMsg={getIn(errors, "campaign.delivery")}
          onSetValue={(value) => setFieldValue("campaign.delivery", value)}
        >
          <RadioField
            label="標準配信(おすすめ)"
            value="standard_delivery"
            disabled={disabled}
          />
          <RadioField
            label="集中化配信"
            value="centralized_delivery"
            disabled={disabled}
          />
        </RadioFieldRow>
      </TableBody>
    </Table>
  )
}
