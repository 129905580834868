import { CircularProgress } from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import { Form, Formik } from "formik"
import React, { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useLocation } from "react-router-dom"
import {
  commaStringsToNumber,
  convertPublishedAPIToForm,
} from "src/components/helpers/FormUtils/utils"
import { CreateForm } from "src/components/pages/CreateOrder/CreateForm"
import { localInitialValues } from "src/components/pages/CreateOrder/form/types/CommonFormValues"
import { convertDraftFormToAPI } from "src/components/pages/CreateOrder/form/utils"
import { RoutePath } from "src/constants/Paths"
import { CampaignId } from "src/domainLayer/models/CampaignInfo"
import { AccountId } from "src/domainLayer/models/TwitterAPI"
import { campaignOperations } from "src/ducks/campaign"
import { refreshCampaignDetail } from "src/ducks/campaign/actions"
import { RootState } from "src/ducks/store"
import { CastAny } from "src/types/Utils"

type OwnProps = {
  children?: never
}

type PublishedUrlQueries = {
  accountId: AccountId
  campaignId: CampaignId
}

/**
 * 入稿依頼新規作成コンポーネント
 */
export const CreateOrder: React.FC<OwnProps> = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const history = useHistory()

  const [circle, setCircle] = useState(true)

  const { campaignId, accountId } = [
    ...new URLSearchParams(location.search).entries(),
  ].reduce(
    (obj, e) => ({
      ...obj,
      [e[0]]: e[1],
    }),
    {}
  ) as PublishedUrlQueries

  useEffect(() => {
    if (campaignId != null && accountId != null) {
      setCircle(true)
      dispatch(
        campaignOperations.fetchPublishedCampaignDetail({
          campaignId,
          accountId,
        })
      )
    } else {
      dispatch(refreshCampaignDetail())
      setCircle(false)
    }
  }, [accountId, campaignId, dispatch])

  const campaignPublishedDetail = useSelector(
    (state: RootState) => state.campaign.publishedDetail
  )

  const initialValues = useMemo(() => {
    if (campaignPublishedDetail == null) {
      return localInitialValues
    }
    setCircle(false)
    return convertPublishedAPIToForm(campaignPublishedDetail)
  }, [campaignPublishedDetail])

  if (circle) {
    return <CircularProgress />
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={async (values, actions) => {
        const campaign = values.campaign
        const grossBudget = commaStringsToNumber(campaign.grossBudget)
        const totalBudget = commaStringsToNumber(campaign.campaignTotalBudget)
        if (grossBudget && totalBudget && grossBudget < totalBudget) {
          alert(
            "キャンペーン予算（ネット）がキャンペーン予算（グロス）を超えています"
          )
          return
        }
        await dispatch(
          campaignOperations.postCampaign(
            // Partial 外しのため CastAny
            convertDraftFormToAPI(values as CastAny),
            () => history.push(RoutePath.ROOT)
          )
        )
        actions.setSubmitting(false)
      }}
    >
      <Form>
        <Typography variant="h5" component="h1">
          入稿依頼新規作成
        </Typography>
        <CreateForm />
      </Form>
    </Formik>
  )
}
