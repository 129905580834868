import { FetchCampaignListResp } from "src/dataLayer/apis/CampaignAPI/types/FetchCampaignListResp"
import {
  BaseFetchAdGroupResp,
  BaseFetchCampaignResp,
  FetchCampaignResp,
  FetchPublishedCampaignResp,
} from "src/dataLayer/apis/CampaignAPI/types/FetchCampaignResp"
import { BaseAdGroup } from "src/domainLayer/models/AdsGroupInfo"
import {
  AdGroupPlacement,
  BaseCampaign,
  CampaignDetail,
  CampaignSummary,
  PublishedCampaignDetail,
  assertIsAdGroupPlacement,
} from "src/domainLayer/models/CampaignInfo"

const copySentence = " - コピー"

export const convertAPIToCampaignSummaryList = (
  src: FetchCampaignListResp
): CampaignSummary[] => {
  return src.data.map((e) => {
    return {
      campaignEndDate: e.campaignEndDate,
      campaignName: e.campaignName,
      campaignStartDate: e.campaignStartDate,
      campaignTotalBudget: e.campaignTotalBudget,
      clientName: e.clientName,
      inhouseCampaignId: e.inhouseCampaignId,
      inhouseCampaignStatus: e.inhouseCampaignStatus,
      publishedFlag: e.publishedFlag,
      sendBackFlag: e.sendBackFlag,
      inhouseOrderSendBackFlag: e.inhouseOrderSendBackFlag,
      inhouseOrderStatus: e.inhouseOrderStatus,
      inhouseOrderCreatedAt: e.inhouseOrderCreatedAt,
      inhouseOrderCreatedBy: e.inhouseOrderCreatedBy,
      inhouseOrderUpdatedAt: e.inhouseOrderUpdatedAt,
      inhouseOrderUpdatedBy: e.inhouseOrderUpdatedBy,
    }
  })
}

export const convertAPIToCampaignDetail = (
  src: FetchCampaignResp,
  isPublished?: boolean
): CampaignDetail => {
  /**
   * キャンペーン情報のデータをフロントに都合の良いように加工
   */
  const c = src.data[0].campaign
  const dCampaign = {
    ...commonCampaign(c, isPublished),
    inhouseCampaignId: c.inhouseCampaignId,
    inhouseCampaignStatus: c.inhouseCampaignStatus,
    inhouseEditable: c.inhouseEditable,
    publishedFlag: c.publishedFlag,
    sendBackFlag: c.sendBackFlag,
    inhouseOrderStatus: c.inhouseOrderStatus,
  }

  /**
   * 広告グループ情報のデータをフロントに都合の良いように加工
   */
  const ags = src.data[0].adGroups
  const dAdGroups = ags.map((ag) => {
    return {
      ...commonAdGroup(ag),
      inhouseAdGroupId: ag.inhouseAdGroupId,
      inhouseAdGroupStatus: ag.inhouseAdGroupStatus,
      inhouseCampaignId: ag.inhouseCampaignId,
      publishedFlag: ag.publishedFlag,
      sendBackFlag: ag.sendBackFlag,
    }
  })

  return { campaign: dCampaign, adGroups: dAdGroups }
}

export const commonConvertAPIToCampaignDetail = (
  src: FetchPublishedCampaignResp,
  isPublished?: boolean
): PublishedCampaignDetail => {
  const c = src.data[0].campaign
  const ags = src.data[0].adGroups

  return {
    campaign: commonCampaign(c, isPublished),
    adGroups: ags.map((ag) => {
      return commonAdGroup(ag)
    }),
  }
}

const commonCampaign = (
  c: BaseFetchCampaignResp,
  isPublished?: boolean
): BaseCampaign => {
  return {
    campaignAppId: c.campaignAppId,
    campaignDailyBudget: c.campaignDailyBudget,
    campaignEndDate: c.campaignEndDate,
    campaignFrequencyCap: c.campaignFrequencyCap,
    campaignFrequencyCapTimeDuration: c.campaignFrequencyCapTimeDuration,
    campaignId: c.campaignId,
    campaignName: isPublished ? c.campaignName + copySentence : c.campaignName,
    campaignObjective: c.campaignObjective,
    campaignStartDate: c.campaignStartDate,
    campaignStatus: c.campaignStatus,
    campaignTotalBudget: c.campaignTotalBudget,
    clientName: c.clientName,
    consultation: c.consultation,
    delivery: c.delivery,
    grossBudget: c.grossBudget,
    twitterAccountName: c.twitterAccountName,
    fundingSourceId: c.fundingSourceId,
    inhouseOrderCreatedAt: c.inhouseOrderCreatedAt,
    inhouseOrderCreatedBy: c.inhouseOrderCreatedBy,
    inhouseOrderUpdatedAt: c.inhouseOrderUpdatedAt,
    inhouseOrderUpdatedBy: c.inhouseOrderUpdatedBy,
  }
}

const commonAdGroup = (ag: BaseFetchAdGroupResp): BaseAdGroup => {
  return {
    adGroupEndTime: ag.adGroupEndTime,
    adGroupId: ag.adGroupId,
    adGroupName: ag.adGroupName,
    // "SEARCH_RESULTS,PROFILES" のような文字列で扱いづらいため分割する
    adGroupPlacementValues: ag.adGroupPlacement
      ? ag.adGroupPlacement
          .split(",")
          .map((v) => v.trim())
          .filter((v): v is AdGroupPlacement => {
            assertIsAdGroupPlacement(v)
            return true
          })
      : [],
    adGroupServingStatus: ag.adGroupServingStatus,
    adGroupStartTime: ag.adGroupStartTime,
    adGroupTotalBudget: ag.adGroupTotalBudget,
    campaignId: ag.campaignId,
    optimizationPreference: ag.optimizationPreference,
    others: {
      age: ag.others.age,
      bidAmount: ag.others.bidAmount,
      bidType: ag.others.bidType,
      bidPricingUnit: ag.others.bidPricingUnit,
      broadMatchKeywords: ag.others.broadMatchKeywords,
      chargeBy: ag.others.chargeBy,
      phraseKeywordsExcluded: ag.others.phraseKeywordsExcluded,
      gender: ag.others.gender,
      language: ag.others.language == null ? [] : ag.others.language,
      location: ag.others.location == null ? [] : ag.others.location,
      lookalikeExpansionSetting: ag.others.lookalikeExpansionSetting,
      tailoredAudienceList: ag.others.tailoredAudienceList,
      tailoredAudienceListExcluded: ag.others.tailoredAudienceListExcluded,
      tailoredAudiencesFromMobileApps:
        ag.others.tailoredAudiencesFromMobileApps,
      tailoredAudiencesFromMobileAppsExcluded:
        ag.others.tailoredAudiencesFromMobileAppsExcluded,
      tailoredAudiencesWebsiteVisitors:
        ag.others.tailoredAudiencesWebsiteVisitors,
      tailoredAudiencesWebsiteVisitorsExcluded:
        ag.others.tailoredAudiencesWebsiteVisitorsExcluded,
      flexibleAudiences: ag.others.flexibleAudiences,
      flexibleAudiencesExcluded: ag.others.flexibleAudiencesExcluded,
      tailoredAudiencesWebsiteVisitorsLookalikes:
        ag.others.tailoredAudiencesWebsiteVisitorsLookalikes,
      flexibleAudiencesLookalikes: ag.others.flexibleAudiencesLookalikes,
      tailoredAudiencesFromMobileAppsLookalikes:
        ag.others.tailoredAudiencesFromMobileAppsLookalikes,
      tailoredAudienceLookalike: ag.others.tailoredAudienceLookalike,
      trackingWithDoubleclick: ag.others.trackingWithDoubleclick,
      campaignRetargeting: ag.others.campaignRetargeting,
      reservationPostTweetIds: ag.others.reservationPostTweetIds!,
      retargetingEngagementType: ag.others.retargetingEngagementType,
      tweetType: ag.others.tweetType,
      tweetIds: ag.others.tweetIds,
      userDevice: ag.others.userDevice,
      platform: ag.others.platform,
      userOsVersion: ag.others.userOsVersion,
      mobileCarriers: ag.others.mobileCarriers,
      deviceActivationDuration: ag.others.deviceActivationDuration,
      eventTargetingIds: ag.others.eventTargetingIds,
      userInterests: ag.others.userInterests,
      followerTargetingLookalikes: ag.others.followerTargetingLookalikes,
      tvShows: ag.others.tvShows,
      conversations: ag.others.conversations,
      mediaCreativeIds: ag.others.mediaCreativeIds,
      requestExpandBroadMatchKeywords:
        ag.others.requestExpandBroadMatchKeywords,
    },
    promotedProductType: ag.promotedProductType,
  }
}
