import { DateTimeFormatForAPI } from "src/dataLayer/apis/types"
import {
  AdGroupPlacement,
  Age,
  CampaignId,
  DeviceActivationDuration,
  Gender,
  LookalikeExpansionSetting,
} from "src/domainLayer/models/CampaignInfo"
import { Brand } from "src/types/Utils"

/**
 * 広告グループ関連
 */

/**
 * 社内用広告グループID
 */
export type InhouseAdGroupId = Brand<number, "InhouseAdGroupId">

export function assertIsInhouseAdGroupId(
  x: unknown
): asserts x is InhouseAdGroupId {
  if (typeof x !== "number") {
    throw new Error(`${x} is not valid InhouseAdGroupId`)
  }
}

/**
 * 1: 入稿依頼新規作成
 * 2: 入稿依頼下書き 保存済み
 * 3: 入稿依頼 運用チームへ提出済み
 * 4: 入稿依頼 運用チーム 下書き保存済み
 * 5: 入稿準備完了
 * 6: バルクシート反映済み
 */
export type InhouseAdGroupStatus = 1 | 2 | 3 | 4 | 5 | 6

/**
 * 広告グループID
 * 既に登録済みの広告グループに対し、自動で付与される
 */
export type AdGroupId = Brand<string, "AdGroupId">

/**
 * Twitter 広告の配信のステータス
 */
export type AdGroupServingStatus = "DRAFT" | "ACTIVE"

export type AdGroupInner = BaseAdGroup & InhouseAdGroup

export type BaseAdGroup = {
  adGroupEndTime?: DateTimeFormatForAPI
  adGroupId?: AdGroupId
  adGroupName?: string
  adGroupPlacementValues: AdGroupPlacement[]
  adGroupServingStatus?: AdGroupServingStatus
  adGroupStartTime?: DateTimeFormatForAPI
  adGroupTotalBudget?: number
  campaignId?: CampaignId
  optimizationPreference: OptimizationPreference
  others: Others
  promotedProductType?: string
}

/**
 * 社内用の広告グループパラメータ
 */
type InhouseAdGroup = {
  inhouseAdGroupId: InhouseAdGroupId
  inhouseAdGroupStatus: InhouseAdGroupStatus
  inhouseCampaignId: number
  publishedFlag: boolean
  sendBackFlag: boolean
}

type Others = {
  age?: Age
  bidAmount: number
  bidPricingUnit: BidPricingUnit
  bidType: BidType
  broadMatchKeywords?: string[]
  campaignRetargeting?: string[]
  chargeBy: ChargeBy
  conversations?: string[]
  deviceActivationDuration?: DeviceActivationDuration
  eventTargetingIds?: string[]
  flexibleAudiences?: string[]
  flexibleAudiencesExcluded?: string[]
  flexibleAudiencesLookalikes?: string[]
  followerTargetingLookalikes?: string[]
  gender: Gender
  language: string[]
  location: string[]
  lookalikeExpansionSetting: LookalikeExpansionSetting
  mediaCreativeIds?: string[]
  mobileCarriers?: string[]
  phraseKeywordsExcluded?: string[]
  platform?: string[]
  requestExpandBroadMatchKeywords: boolean
  reservationPostTweetIds: string[]
  retargetingEngagementType?: RetargetingEngagementType
  retargetingType?: RetargetingType
  tailoredAudienceList?: string[]
  tailoredAudienceListExcluded?: string[]
  tailoredAudienceLookalike?: string[]
  tailoredAudiencesFromMobileApps?: string[]
  tailoredAudiencesFromMobileAppsExcluded?: string[]
  tailoredAudiencesFromMobileAppsLookalikes?: string[]
  tailoredAudiencesWebsiteVisitors?: string[]
  tailoredAudiencesWebsiteVisitorsExcluded?: string[]
  tailoredAudiencesWebsiteVisitorsLookalikes?: string[]
  trackingWithDoubleclick?: string
  tvShows?: string[]
  tweetIds: string[]
  tweetType?: TweetType
  userDevice?: string[]
  userInterests?: string[]
  userOsVersion?: string[]
}

/**
 * フロント用の値
 * バルクシートには出力しないが編集画面で初期描画するために使う
 */
export type RetargetingType =
  | ""
  | "Tweet_Impression"
  | "Tweet_Impression_and_Engagement"

export type RetargetingEngagementType =
  | ""
  | "IMPRESSION"
  | "ENGAGEMENT"
  | "VIDEO_VIEW"
  | "VIDEO_PLAYBACK_PARTIAL"
  | "VIDEO_PLAYBACK_COMPLETE"

export type TweetType = "" | "Organic" | "Campaign" | "OrganicAndCampaign"

/**
 * 入札タイプ
 */
export type BidType = "AUTO_BID" | "AUTO_BID_WITH_TARGET" | "MAX_BID"
/**
 * 最適化設定
 */
export type OptimizationPreference = "NONE" | "ENGAGEMENT" | "CONVERSIONS"
/**
 * 請求方法
 */
export type ChargeBy = "-" | "IMPRESSION" | "INSTALL"
/**
 * 入札価格単位
 */
export type BidPricingUnit =
  | "-"
  | "COST_PER_VIDEO_VIEW"
  | "COST_PER_QUALITY_VIEW"
  | "COST_PER_INSTALL"
  | "CPM"
