import {
  AdGroupId,
  AdGroupServingStatus,
  BidPricingUnit,
  BidType,
  ChargeBy,
  InhouseAdGroupId,
  InhouseAdGroupStatus,
  OptimizationPreference,
  RetargetingEngagementType,
  RetargetingType,
  TweetType,
} from "src/domainLayer/models/AdsGroupInfo"
import {
  AdGroupPlacement,
  Age,
  CampaignObjective,
  Consultation,
  Delivery,
  DeviceActivationDuration,
  Gender,
  InhouseCampaignId,
  InhouseCampaignStatus,
  LookalikeExpansionSetting,
} from "src/domainLayer/models/CampaignInfo"
import { getNow } from "src/utils/DateTimeUtils"

/**
 * 条件に依らない、共通の入力項目
 */
export type CommonFormValues = {
  adGroups: AdGroup[]
  campaign: Campaign
  /**
   * 「完了」の場合のみバリデーションを実行するための値
   * ユーザーが何のアクションを実行するか事前に決めることができないため、ボタンのクリックイベント時に変更する
   */
  submitAsComplete: boolean
}

export type Campaign = BaseCampaignValues & InhouseCampaignValues

export type BaseCampaignValues = {
  campaignAppId?: string
  campaignDailyBudget?: number
  campaignEndDate?: Date
  campaignFrequencyCap?: string
  campaignFrequencyCapTimeDuration?: string
  campaignName?: string
  campaignObjective: CampaignObjective | ""
  campaignStartDate?: Date
  campaignStatus: string
  campaignTotalBudget?: number
  clientName?: string
  consultation: Consultation
  delivery: Delivery
  fundingSourceId?: string
  grossBudget?: number
  inhouseCampaignStatus?: number
  inhouseOrderCreatedAt?: string
  inhouseOrderCreatedBy?: string
  inhouseOrderUpdatedAt?: string
  inhouseOrderUpdatedBy?: string
  isEdit?: boolean
  twitterAccountName?: string
}

type InhouseCampaignValues = {
  inhouseCampaignId: InhouseCampaignId
  inhouseCampaignStatus: InhouseCampaignStatus
  publishedFlag: boolean
  sendBackFlag: boolean
}

export const DefaultAdGroupPlacementValues: Readonly<Record<
  AdGroupPlacement,
  boolean
>> = {
  PROFILES: false,
  SEARCH_RESULTS: false,
  TIMELINES: false,
}

export type AdGroup = BaseAdGroupValues & InhouseAdGroupValues

export type BaseAdGroupValues = {
  adGroupEndTime?: Date
  adGroupId?: AdGroupId
  adGroupName?: string
  adGroupPlacementValues: Record<AdGroupPlacement, boolean>
  adGroupServingStatus?: AdGroupServingStatus
  adGroupStartTime?: Date
  adGroupTotalBudget?: number
  // 営業が広告グループをコピーする際に使う値
  isEdit?: boolean
  optimizationPreference: OptimizationPreference
  others: Others
  promotedProductType?: string
}

type InhouseAdGroupValues = {
  inhouseAdGroupId?: InhouseAdGroupId
  inhouseAdGroupStatus: InhouseAdGroupStatus
  publishedFlag: boolean
  sendBackFlag: boolean
}

export type Others = {
  age: Age | ""
  bidAmount: number
  bidPricingUnit: BidPricingUnit
  bidType: BidType
  broadMatchKeywords?: string
  campaignRetargeting?: string
  chargeBy: ChargeBy
  commonFlexibleAudiences?: string
  commonTailoredAudienceList?: string
  commonTailoredAudiencesFromMobileApps?: string
  commonTailoredAudiencesWebsiteVisitors?: string
  conversations?: string
  deviceActivationDuration: DeviceActivationDuration | ""
  eventTargetingIds?: string
  flexibleAudiences?: string
  flexibleAudiencesExcluded?: string
  followerTargetingLookalikes?: string
  gender: Gender
  isTailoredAudienceLookalike: boolean
  language?: string
  location?: string
  lookalikeExpansionSetting?: LookalikeExpansionSetting
  mediaCreativeIds?: string
  mobileCarriers?: string
  phraseKeywordsExcluded?: string
  platform?: string
  requestExpandBroadMatchKeywords: boolean
  reservationPostTweetIds?: string
  retargetingEngagementType?: RetargetingEngagementType
  retargetingType?: RetargetingType
  tailoredAudienceList?: string
  tailoredAudienceListExcluded?: string
  tailoredAudiencesFromMobileApps?: string
  tailoredAudiencesFromMobileAppsExcluded?: string
  tailoredAudiencesWebsiteVisitors?: string
  tailoredAudiencesWebsiteVisitorsExcluded?: string
  trackingWithDoubleclick?: string
  tvShows?: string
  tweetIds?: string
  tweetType?: TweetType
  userDevice?: string
  userInterests?: string
  userOsVersion?: string
}

/**
 * API から取得する前の初期値
 */
export const localInitialValues: CommonFormValues = {
  adGroups: [
    {
      adGroupPlacementValues: DefaultAdGroupPlacementValues,
      optimizationPreference: "NONE",
      others: {
        age: "",
        deviceActivationDuration: "",
        gender: "ANY_GENDER",
        bidType: "AUTO_BID",
        bidAmount: 0,
        chargeBy: "-",
        bidPricingUnit: "-",
        isTailoredAudienceLookalike: false,
        requestExpandBroadMatchKeywords: false,
      },
      isEdit: false,
      inhouseAdGroupStatus: 1,
      publishedFlag: false,
      sendBackFlag: false,
    },
  ],
  campaign: {
    campaignEndDate: getNow(),
    campaignObjective: "",
    campaignStartDate: getNow(),
    campaignStatus: "DRAFT",
    consultation: "not_implementation",
    delivery: "standard_delivery",
    inhouseCampaignId: 0 as InhouseCampaignId,
    isEdit: false,
    inhouseCampaignStatus: 1,
    publishedFlag: false,
    sendBackFlag: false,
    inhouseOrderCreatedAt: "",
    inhouseOrderCreatedBy: "",
    inhouseOrderUpdatedAt: undefined,
    inhouseOrderUpdatedBy: undefined,
  },
  submitAsComplete: false,
}
