import { InputProps, TextField, TextFieldProps } from "@material-ui/core"
import { useFormikContext } from "formik"
import React, { useEffect, useState } from "react"
import {
  commaStringsToNumber,
  numberWithCommas,
} from "src/components/helpers/FormUtils/utils"
import { CommonFormValues } from "src/components/pages/CreateOrder/form/types/CommonFormValues"

type OwnProps = {
  children?: never
  initialValue?: number | undefined | string
  onSetValue: (value: number | undefined | string) => void
} & TextFieldProps

/**
 * number 型のテキストフィールド用コンポーネント
 */
export const FastNumberField: React.FC<OwnProps> = ({
  initialValue,
  onSetValue,
  ...rest
}) => {
  const { values } = useFormikContext<CommonFormValues>()
  // We need to keep and update the state of the cell normally
  const [tempValue, setTempValue] = useState(initialValue)

  const onChange: InputProps["onChange"] = (e) => {
    setTempValue(e.target.value)
  }

  // We'll only update the external data when the input is blurred
  const _onBlur: InputProps["onBlur"] = () => {
    onSetValue(tempValue)
    let newTempValue = tempValue
    if (newTempValue) {
      newTempValue = commaStringsToNumber(newTempValue)

      if (rest.name === "campaign.campaignTotalBudget") {
        const grossBudget = commaStringsToNumber(values.campaign.grossBudget)
        if (grossBudget && newTempValue && grossBudget < newTempValue) {
          alert(
            "キャンペーン予算（ネット）がキャンペーン予算（グロス）を超えています"
          )
        }
      }
      if (rest.name === "campaign.grossBudget") {
        const campaignTotalBudget = commaStringsToNumber(
          values.campaign.campaignTotalBudget
        )
        if (
          campaignTotalBudget &&
          newTempValue &&
          campaignTotalBudget > newTempValue
        ) {
          alert(
            "キャンペーン予算（ネット）がキャンペーン予算（グロス）を超えています"
          )
        }
      }
    }
  }

  // If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    setTempValue(initialValue)
  }, [initialValue])

  return (
    <TextField
      {...rest}
      onChange={onChange}
      onBlur={_onBlur}
      value={numberWithCommas(tempValue)}
      type="text"
    />
  )
}
