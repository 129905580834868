import { commaStringsToNumber } from "src/components/helpers/FormUtils/utils"
import { CommonFormValues } from "src/components/pages/CreateOrder/form/types/CommonFormValues"
import { PostCampaignReq } from "src/dataLayer/apis/CampaignAPI/types/PostCampaignReq"
import {
  AdGroupPlacement,
  assertIsAdGroupPlacement,
} from "src/domainLayer/models/CampaignInfo"
import { formatDateAsAPI } from "src/utils/DateTimeUtils"
import {
  emptyValueToUndefined,
  semicolonStringToArray,
} from "src/utils/StringUtils"

/**
 * この form 固有の utils
 */

/**
 * 下書き form  -> 新規作成 API
 */
export const convertDraftFormToAPI = (v: CommonFormValues): PostCampaignReq => {
  return {
    campaign: {
      campaignDailyBudget: commaStringsToNumber(v.campaign.campaignDailyBudget),
      campaignAppId: semicolonStringToArray(v.campaign.campaignAppId),
      campaignEndDate: formatDateAsAPI(v.campaign.campaignEndDate),
      campaignFrequencyCap: v.campaign.campaignFrequencyCap,
      campaignFrequencyCapTimeDuration:
        v.campaign.campaignFrequencyCapTimeDuration,
      campaignId: "",
      campaignName: v.campaign.campaignName,
      campaignObjective: emptyValueToUndefined(v.campaign.campaignObjective),
      campaignStartDate: formatDateAsAPI(v.campaign.campaignStartDate),
      campaignStatus: v.campaign.campaignStatus,
      campaignTotalBudget: commaStringsToNumber(v.campaign.campaignTotalBudget),
      clientName: v.campaign.clientName,
      consultation: v.campaign.consultation === "implementation",
      delivery: v.campaign.delivery === "standard_delivery",
      fundingSourceId: v.campaign.fundingSourceId,
      grossBudget: commaStringsToNumber(v.campaign.grossBudget),
      // 下書き保存のため、必ずこの値
      inhouseCampaignStatus: 2,
      // 下書き保存のため、必ずこの値
      publishedFlag: false,
      // 下書き保存のため、必ずこの値
      sendBackFlag: false,
      twitterAccountName: v.campaign.twitterAccountName,
    },
    adGroups: v.adGroups.map((va) => {
      return {
        adGroupEndTime: formatDateAsAPI(va.adGroupEndTime),
        adGroupId: "",
        adGroupName: va.adGroupName,
        adGroupPlacement: Object.entries(va.adGroupPlacementValues)
          .filter((v) => v[1])
          .reduce((accum, curr) => {
            assertIsAdGroupPlacement(curr[0])
            accum.push(curr[0])
            return accum
          }, [] as AdGroupPlacement[])
          .join(","),
        // 下書き保存のため、必ずこの値
        adGroupServingStatus: "DRAFT",
        adGroupStartTime: formatDateAsAPI(va.adGroupStartTime),
        adGroupTotalBudget: commaStringsToNumber(va.adGroupTotalBudget),
        // 下書き保存のため、必ずこの値
        inhouseAdGroupStatus: 2,
        optimizationPreference: va.optimizationPreference,
        others: {
          age: emptyValueToUndefined(va.others.age),
          bidAmount: va.others.bidAmount,
          bidType: va.others.bidType,
          broadMatchKeywords: semicolonStringToArray(
            va.others.broadMatchKeywords
          ),
          campaignRetargeting: semicolonStringToArray(
            va.others.campaignRetargeting
          ),
          deviceActivationDuration: emptyValueToUndefined(
            va.others.deviceActivationDuration
          ),
          eventTargetingIds: semicolonStringToArray(
            va.others.eventTargetingIds
          ),
          followerTargetingLookalikes: semicolonStringToArray(
            va.others.followerTargetingLookalikes
          ),
          gender: va.others.gender,
          language: semicolonStringToArray(va.others.language),
          location: semicolonStringToArray(va.others.location),
          lookalikeExpansionSetting: va.others.lookalikeExpansionSetting,
          mediaCreativeIds: semicolonStringToArray(va.others.mediaCreativeIds),
          mobileCarriers: semicolonStringToArray(va.others.mobileCarriers),
          platform: semicolonStringToArray(va.others.platform),
          retargetingEngagementType: va.others.retargetingEngagementType,
          tailoredAudienceList: va.others.isTailoredAudienceLookalike
            ? []
            : semicolonStringToArray(va.others.commonTailoredAudienceList),
          tailoredAudienceLookalike: va.others.isTailoredAudienceLookalike
            ? semicolonStringToArray(va.others.commonTailoredAudienceList)
            : [],
          tailoredAudiencesFromMobileApps: va.others.isTailoredAudienceLookalike
            ? []
            : semicolonStringToArray(
                va.others.commonTailoredAudiencesFromMobileApps
              ),
          tailoredAudiencesFromMobileAppsLookalikes: va.others
            .isTailoredAudienceLookalike
            ? semicolonStringToArray(
                va.others.commonTailoredAudiencesFromMobileApps
              )
            : [],
          tailoredAudiencesWebsiteVisitors: va.others
            .isTailoredAudienceLookalike
            ? []
            : semicolonStringToArray(
                va.others.commonTailoredAudiencesWebsiteVisitors
              ),
          tailoredAudiencesWebsiteVisitorsLookalikes: va.others
            .isTailoredAudienceLookalike
            ? semicolonStringToArray(
                va.others.commonTailoredAudiencesWebsiteVisitors
              )
            : [],
          flexibleAudiences: va.others.isTailoredAudienceLookalike
            ? []
            : semicolonStringToArray(va.others.commonFlexibleAudiences),
          flexibleAudiencesLookalikes: va.others.isTailoredAudienceLookalike
            ? semicolonStringToArray(va.others.commonFlexibleAudiences)
            : [],
          tailoredAudienceListExcluded: semicolonStringToArray(
            va.others.tailoredAudienceListExcluded
          ),
          tailoredAudiencesFromMobileAppsExcluded: semicolonStringToArray(
            va.others.tailoredAudiencesFromMobileAppsExcluded
          ),
          tailoredAudiencesWebsiteVisitorsExcluded: semicolonStringToArray(
            va.others.tailoredAudiencesWebsiteVisitorsExcluded
          ),
          flexibleAudiencesExcluded: semicolonStringToArray(
            va.others.flexibleAudiencesExcluded
          ),
          trackingWithDoubleclick: va.others.trackingWithDoubleclick,
          tvShows: semicolonStringToArray(va.others.tvShows),
          tweetIds: semicolonStringToArray(va.others.tweetIds),
          reservationPostTweetIds: semicolonStringToArray(
            va.others.reservationPostTweetIds
          ),
          tweetType: va.others.tweetType,
          phraseKeywordsExcluded: semicolonStringToArray(
            va.others.phraseKeywordsExcluded
          ),
          conversations: semicolonStringToArray(va.others.conversations),
          userDevice: semicolonStringToArray(va.others.userDevice),
          userInterests: semicolonStringToArray(va.others.userInterests),
          userOsVersion: semicolonStringToArray(va.others.userOsVersion),
          bidPricingUnit: va.others.bidPricingUnit,
          chargeBy: va.others.chargeBy,
          requestExpandBroadMatchKeywords:
            va.others.requestExpandBroadMatchKeywords,
        },
        promotedProductType: va.promotedProductType,
        // 下書き保存のため、必ずこの値
        publishedFlag: false,
        // 下書き保存のため、必ずこの値
        sendBackFlag: false,
      }
    }),
  }
}
