import * as AdGroupAPI from "src/dataLayer/apis/AdGroupAPI"
import { PostAdGroupsReq } from "src/dataLayer/apis/AdGroupAPI/types/PostAdGroupsReq"
import { PutAdGroupsReq } from "src/dataLayer/apis/AdGroupAPI/types/PutAdGroupsReq"
import * as CampaignAPI from "src/dataLayer/apis/CampaignAPI"
import { PostCampaignReq } from "src/dataLayer/apis/CampaignAPI/types/PostCampaignReq"
import { PutCampaignAndAdGroupsStatusReq } from "src/dataLayer/apis/CampaignAPI/types/PutCampaignAndAdGroupsStatusReq"
import { PutCampaignReq } from "src/dataLayer/apis/CampaignAPI/types/PutCampaignReq"
import * as CognitoAPI from "src/dataLayer/apis/CognitoAPI"
import { InhouseAdGroupId } from "src/domainLayer/models/AdsGroupInfo"
import {
  CampaignId,
  InhouseCampaignId,
} from "src/domainLayer/models/CampaignInfo"
import { AccountId } from "src/domainLayer/models/TwitterAPI"
import {
  commonConvertAPIToCampaignDetail,
  convertAPIToCampaignDetail,
  convertAPIToCampaignSummaryList,
} from "src/gateway/campaignGateway"
import { AppThunkAction } from "src/types/ReduxTypes"

import * as actions from "./actions"

/**
 * 入稿(キャンペーン・広告グループ全体)の一覧を取得する
 */
export const fetchCampaignList = (): AppThunkAction<void> => {
  return async (dispatch) => {
    dispatch(actions.fetchCampaignList.started())

    let result
    try {
      result = await CampaignAPI.fetchCampaignList(
        await CognitoAPI.fetchOrGetIdToken()
      )
    } catch (error) {
      console.error(error)
      actions.fetchCampaignList.failed({
        params: undefined,
        error: undefined,
      })
      alert(
        "入稿一覧の取得に失敗しました。後ほど再度試すか、開発部までお問い合わせください。"
      )
      return
    }

    dispatch(
      actions.fetchCampaignList.done({
        result: convertAPIToCampaignSummaryList(result),
      })
    )
  }
}

/**
 * 入稿(キャンペーン・広告グループ全体)の詳細情報を取得する
 */
export const fetchCampaignDetail = (
  inhouseCampaignId: InhouseCampaignId
): AppThunkAction => {
  return async (dispatch) => {
    dispatch(actions.fetchCampaignDetail.started())

    let result
    try {
      result = await CampaignAPI.fetchCampaign(
        await CognitoAPI.fetchOrGetIdToken(),
        { inhouse_campaign_id: inhouseCampaignId }
      )
    } catch (error) {
      console.error(error)
      console.log("inhouseCampaignId", inhouseCampaignId)
      actions.fetchCampaignDetail.failed({
        params: undefined,
        error: undefined,
      })
      alert(
        "入稿の詳細情報の取得に失敗しました。後ほど再度試すか、開発部までお問い合わせください。"
      )
      return
    }

    dispatch(
      actions.fetchCampaignDetail.done({
        result: convertAPIToCampaignDetail(result),
      })
    )
  }
}

/**
 * 入稿(キャンペーン・広告グループ全体)の新規作成
 * @param params
 * @param finalize 成功した場合にのみ実行する終端処理。この function で try-catch する（=例外が外に伝搬しない）ため、エラーの場合には実行したくない処理はここに渡す。
 */
export const postCampaign = (
  params: PostCampaignReq,
  finalize: () => void
): AppThunkAction => {
  return async (dispatch) => {
    dispatch(actions.postCampaign.started())

    let result
    try {
      result = await CampaignAPI.postCampaign(
        await CognitoAPI.fetchOrGetIdToken(),
        params
      )
    } catch (error) {
      console.error(error)
      console.log("params", params)
      actions.postCampaign.failed({
        params: undefined,
        error: undefined,
      })
      alert(
        "入稿の新規作成に失敗しました。後ほど再度試すか、開発部までお問い合わせください。"
      )
      return
    }

    dispatch(actions.postCampaign.done({ result }))
    finalize()
  }
}

/**
 * 継続している入稿(キャンペーン・広告グループ全体)の詳細情報を取得する
 */
export const fetchPublishedCampaignDetail = (params: {
  accountId: AccountId
  campaignId: CampaignId
}): AppThunkAction => {
  return async (dispatch) => {
    dispatch(actions.fetchPublishedCampaignDetail.started())

    let result
    try {
      result = await CampaignAPI.fetchPublishedCampaign(
        await CognitoAPI.fetchOrGetIdToken(),
        {
          campaign_id: params.campaignId,
          account_id: params.accountId,
        }
      )
    } catch (error) {
      console.error(error)
      console.log("params", params)
      actions.fetchCampaignDetail.failed({
        params: undefined,
        error: undefined,
      })
      alert(
        "入稿の継続詳細情報の取得に失敗しました。後ほど再度試すか、開発部までお問い合わせください。"
      )
      return
    }

    dispatch(
      actions.fetchPublishedCampaignDetail.done({
        result: commonConvertAPIToCampaignDetail(result, true),
      })
    )
  }
}

/**
 * キャンペーンの更新
 */
export const updateCampaign = (
  params: CampaignAPI.UrlParams & PutCampaignReq
): AppThunkAction => {
  return async (dispatch) => {
    dispatch(actions.updateCampaignDetail.started())

    let result
    try {
      result = await CampaignAPI.putCampaign(
        await CognitoAPI.fetchOrGetIdToken(),
        { inhouse_campaign_id: params.inhouse_campaign_id },
        { campaign: params.campaign }
      )
    } catch (error) {
      console.error(error)
      console.log("params", params)
      actions.updateCampaignDetail.failed({
        params: undefined,
        error: undefined,
      })
      alert(
        "クライアント情報・キャンペーンの編集に失敗しました。後ほど再度試すか、開発部までお問い合わせください。"
      )
      return
    }

    dispatch(actions.updateCampaignDetail.done({ result }))
  }
}

/**
 * 入稿の配下の広告の新規作成
 */
export const createRelatedAdGroups = (
  params: Omit<AdGroupAPI.UrlParams, "inhouse_ad_group_id"> & PostAdGroupsReq
): AppThunkAction => {
  return async (dispatch) => {
    dispatch(actions.createRelatedAdGroups.started())

    try {
      await AdGroupAPI.postAdGroups(
        await CognitoAPI.fetchOrGetIdToken(),
        {
          inhouse_campaign_id: params.inhouse_campaign_id,
        },
        {
          adGroups: params.adGroups,
        }
      )
    } catch (error) {
      console.error(error)
      console.log("params", params)
      actions.createRelatedAdGroups.failed({
        params: undefined,
        error: undefined,
      })
      alert(
        "広告グループの新規作成に失敗しました。後ほど再度試すか、開発部までお問い合わせください。"
      )
      return
    }

    dispatch(actions.createRelatedAdGroups.done({}))
  }
}

/**
 * 入稿の配下の広告(単体)の編集
 */
export const updateRelatedAdGroups = (
  params: AdGroupAPI.UrlParams & PutAdGroupsReq
): AppThunkAction => {
  return async (dispatch) => {
    dispatch(actions.updateRelatedAdGroups.started())

    try {
      await AdGroupAPI.putAdGroups(
        await CognitoAPI.fetchOrGetIdToken(),
        {
          inhouse_ad_group_id: params.inhouse_ad_group_id,
          inhouse_campaign_id: params.inhouse_campaign_id,
        },
        {
          adGroups: params.adGroups,
        }
      )
    } catch (error) {
      console.error(error)
      console.log("params", params)
      actions.updateRelatedAdGroups.failed({
        params: undefined,
        error: undefined,
      })
      alert(
        "広告グループの編集に失敗しました。後ほど再度試すか、開発部までお問い合わせください。"
      )
      return
    }

    dispatch(actions.updateRelatedAdGroups.done({}))
  }
}

/**
 * 入稿(キャンペーン・広告グループ全体)のステータス更新
 */
export const updateStatus = (
  params: CampaignAPI.UrlParams & PutCampaignAndAdGroupsStatusReq,
  finalize: () => void
): AppThunkAction => {
  return async (dispatch) => {
    dispatch(actions.updateStatus.started())
    console.log("updateStatus", params)
    try {
      await CampaignAPI.putCampaignAndAdGroupsStatus(
        await CognitoAPI.fetchOrGetIdToken(),
        {
          inhouse_campaign_id: params.inhouse_campaign_id,
        },
        {
          adGroups: params.adGroups,
          campaign: params.campaign,
        }
      )
    } catch (error) {
      console.error(error)
      console.log("params", params)
      actions.updateStatus.failed({
        params: undefined,
        error: undefined,
      })
      alert(
        "入稿のステータスの変更に失敗しました。後ほど再度試すか、開発部までお問い合わせください。"
      )
      return
    }

    dispatch(actions.updateStatus.done({}))
    finalize()
  }
}

/**
 * 入稿(キャンペーン・広告グループ全体)の削除
 */
export const deleteCampaign = (
  inhouseCampaignId: InhouseCampaignId,
  finalize: () => void
): AppThunkAction => {
  return async (dispatch) => {
    dispatch(actions.deleteCampaign.started())

    try {
      await CampaignAPI.deleteCampaign(await CognitoAPI.fetchOrGetIdToken(), {
        inhouse_campaign_id: inhouseCampaignId,
      })
    } catch (error) {
      console.error(error)
      console.log("inhouseCampaignId", inhouseCampaignId)
      actions.deleteCampaign.failed({
        params: undefined,
        error: undefined,
      })
      alert(
        "入稿の削除に失敗しました。後ほど再度試すか、開発部までお問い合わせください。"
      )
      return
    }

    dispatch(actions.deleteCampaign.done({}))
    finalize()
  }
}

/**
 * 広告単体の削除
 */
export const deleteAdGroup = (
  parentInhouseCampaignId: InhouseCampaignId,
  inhouseAdGroupId: InhouseAdGroupId,
  finalize: () => void
): AppThunkAction => {
  return async (dispatch) => {
    dispatch(actions.deleteAdGroup.started())

    try {
      await AdGroupAPI.deleteAdGroup(await CognitoAPI.fetchOrGetIdToken(), {
        inhouse_campaign_id: parentInhouseCampaignId,
        inhouse_ad_group_id: inhouseAdGroupId,
      })
    } catch (error) {
      console.error(error)
      console.log("parentInhouseCampaignId", parentInhouseCampaignId)
      console.log("inhouseAdGroupId", inhouseAdGroupId)
      actions.deleteAdGroup.failed({
        params: undefined,
        error: undefined,
      })
      alert(
        "広告グループの削除に失敗しました。後ほど再度試すか、開発部までお問い合わせください。"
      )
      return
    }

    dispatch(actions.deleteAdGroup.done({}))
    finalize()
  }
}
